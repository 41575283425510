import {
    Hero,
    DiscoverLendOffer,
    SEO,
    Waistband,
} from '@brandedholdings/react-components';
import PropTypes from 'prop-types';
import React from 'react';
import PagePost from '../../models/PagePost';
import { mediaNet as pageMeta } from '../../data/page_meta.yml';
import { siteMeta } from '../../data/site_meta.yml';
import PageWrapper from '../../components/DefaultPageWrapper';
import './default.scss';

class DiscoverLend extends React.Component {
    getChildContext() {
        return { siteMeta, pageMeta };
    }

    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);
        const { template } = pageMeta;

        return (
            <PageWrapper location={this.props.location} templateType={template}>
                <SEO post={ post } />
                <Hero
                    heading="Great News!"
                    subheading="Offers are available!"
                    actionText={null}
                    actionLink={"#"}
                    heroModifiers="hero--arrow"
                    actionModifiers={null}
                />
                <Waistband color="gray">
                    <div className="layout">
                        <DiscoverLendOffer />
                    </div>
                </Waistband>
            </PageWrapper>
        );
    }
}

DiscoverLend.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object,
};

export default DiscoverLend;
